import https from '../../http.js'

// 系统设置
export function setweb(data) {
	return https.post('/index/index/web', data)
}
// 轮播图
export function charts(data) {
	return https.post('/index/index/charts', data)
}
// 公告
export function advert(data) {
	return https.post('/index/index/advert', data)
}
// 服务项目分类
export function scate1(data) {
	return https.post('/index/index/scate1', data)
}
// 商城分类
export function scate2(data) {
	return https.post('/index/index/scate2', data)
}
// 热门搜索列表
export function hot(data) {
	return https.post('/index/index/hot', data)
}
// 热门搜索
export function search(data) {
	return https.post('/index/index/search', data)
}
// 热门搜索添加
export function hotinc(data) {
	return https.post('/index/index/hotInc', data)
}
// 项目套餐
export function shop(data) {
	return https.post('/index/index/shop', data)
}
// 商城商品
export function shops(data) {
	return https.post('/index/index/shops', data)
}
// 我的购物车
export function myCart(data) {
	return https.post('/index/order/myCart', data)
}
// 我的订单
export function myOrders(data) {
	return https.post('/index/users/myOrders', data)
}
// 订单详情
export function orderDetail(data) {
	return https.post('/index/users/orderDetail', data)
}
// 我的资料
export function myUser(data) {
	return https.post('/index/users/myUser', data)
}
// 我的资料更新
export function userUp(data) {
	return https.post('/index/users/userUp', data)
}
// 详情
export function shopde(data) {
	return https.post('/index/index/shopde', data)
}
// 加入购物车
export function addCart(data) {
	return https.post('/index/order/addCart', data)
}
// 删除购物车单个商品
export function Cartdel(data) {
	return https.post('/index/order/Cartdel', data)
}
// 删除购物车多个商品
export function Cartalldel(data) {
	return https.post('/index/order/Cartalldel', data)
}
// 订单结算页面 展示数据
export function check(data) {
	return https.post('/index/order/check', data)
}
//  创建订单  点击立即支付加入订单
export function orderset(data) {
	return https.post('/index/order/set', data)
}
//  服务项目项目套餐列表
export function shopList(data) {
	return https.post('/index/index/shopList', data)
}
//  购物车数量更新
export function Cartup(data) {
	return https.post('/index/order/Cartup', data)
}
//  购物车数量+
export function Cartplus(data) {
	return https.post('/index/order/Cartplus', data)
}
//  购物车数量-
export function Cartreduce(data) {
	return https.post('/index/order/Cartreduce', data)
}
//  传输code
export function code(data) {
	return https.post('/index/users/code', data)
}
// 微信JSSDK授权
export function jsauth(data) {
	return https.post('/api/auth/jsauth', data)
}
// 立即支付
export function orderpay(data) {
	return https.post('/index/order/pay', data)
}
// 取消订单
export function orderdel(data) {
	return https.post('/index/order/orderdel', data)
}
// 首页服务项目
export function shopList1(data) {
	return https.post('/index/index/shopList1', data)
}