import { createApp } from 'vue'
import App from './App.vue'
import axios from './utils/axios.js'
import router from './router/index.js'
import 'amfe-flexible';
import 'vant/lib/index.css';
import wx from "weixin-js-sdk";
import { Tabbar,TabbarItem,Swipe,SwipeItem,NoticeBar,Checkbox, CheckboxGroup,Stepper,Icon,Uploader,Sticky,List,PullRefresh,Toast,Popup,Dialog,Overlay,Field } from 'vant';


var app = createApp(App)
app.use(router).use(Tabbar).use(TabbarItem).use(Swipe).use(SwipeItem).use(NoticeBar).use(Checkbox).use(CheckboxGroup).use(Stepper).use(Icon).use(Uploader).use(Sticky).use(List).use(PullRefresh).use(Toast).use(Popup).use(Dialog).use(Overlay).use(Field).mount('#app')
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$wxsdk = wx