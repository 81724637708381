import { createRouter,createWebHistory } from 'vue-router'
import wx from 'weixin-js-sdk'
import axios from '../utils/api/index.js'
const router = createRouter({
	history:createWebHistory(),
	routes: [
		{
			path:'/',
			component:() => import('@/view/HoMe'),
			meta: {
				title: '熙恩健康'
			}
		},
		{
			path:'/PointsMall',
			component:() => import('@/view/PointsMall'),
			meta: {
				title: '商城'
			}
		},
		{
			path:'/ShoppingCart',
			component:() => import('@/view/ShoppingCart'),
			meta: {
				title: '购物车'
			}
		},
		{
			path:'/MoNe',
			component:() => import('@/view/MoNe'),
			meta: {
				title: '我的'
			}
		},
		{
			path:'/SearCh',
			component:() => import('@/view/home/SearCh'),
			meta: {
				title: '套餐搜索'
			}
		},
		{
			path:'/MyInfo',
			component:() => import('@/view/my/MyInfo'),
			meta: {
				title: '个人主页'
			}
		},
		{
			path:'/MyOrder',
			component:() => import('@/view/my/MyOrder'),
			meta: {
				title: '我的订单'
			}
		},
		{
			path:'/MyDetail',
			component:() => import('@/view/my/MyDetail'),
			meta: {
				title: '待付款'
			}
		},
		{
			path:'/SettleMent',
			component:() => import('@/view/my/SettleMent'),
			meta: {
				title: '订单结算'
			}
		},
		{
			path:'/AagreeMent',
			component:() => import('@/view/my/AagreeMent'),
			meta: {
				title: '用户协议'
			}
		},
		{
			path:'/HomeDetail',
			component:() => import('@/view/home/HomeDetail'),
			meta: {
				title: '项目详情'
			}
		},
		{
			path:'/CarDetail',
			component:() => import('@/view/home/CarDetail'),
			meta: {
				title: '商品详情'
			}
		},
		{
			path:'/ClassiFication',
			component:() => import('@/view/home/ClassiFication'),
			meta: {
				title: '服务项目列表'
			}
		}
		
	]
})
router.beforeEach((to, from, next)=>{
	document.title = to.meta.title ? to.meta.title : ' ';
	next();
})
router.afterEach(() => { // 每个页面都调用一遍都重新拉取config
	let u = navigator.userAgent
	let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
	if (!isAndroid) { // IOS
		if (window.entryUrl == '' || window.entryUrl == undefined) {
			var url = location.href.split('#')[0]
			window.entryUrl = url
		}
	} else { // 安卓
		window.entryUrl = location.href.split('#')[0]
	}
	console.log(window.entryUrl)
	var parmsname = {
		pageurl: encodeURIComponent(window.entryUrl)
	}
	axios.jsauth(parmsname).then((res) => {
		if (res.data.error_code == 200) {
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.data.data.appid, // 必填，公众号的唯一标识
				timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
				signature: res.data.data.signature, // 必填，签名，见附录1
				jsApiList: ["scanQRCode", "openLocation", "getLocation",
					"updateAppMessageShareData", "updateTimelineShareData", 'chooseWXPay'
				] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			});
		}
	})
})
export default router;