import axios from "./axios.js"
const http = {
	get(url, params, type) {
		const config = {
			method: 'get',
			url: url,
			urlType: type
		}
		if (params) {
			config.data = params
		}
		return axios(config)
	},
	post(url, params, type) {
		const config = {
			method: 'post',
			url: url,
			urlType: type
		}
		if (params) {
			config.data = params
		}
		return axios(config)
	},
	put(url, params, type) {
		const config = {
			method: 'put',
			url: url,
			urlType: type
		}
		if (params) {
			config.data = params
		}
		return axios(config)
	},
	delete(url, params, type) {
		const config = {
			method: 'delete',
			url: url,
			urlType: type
		}
		if (params) {
			config.data = params
		}
		return axios(config)
	}
}


export default http;
