import {
	setweb,
	charts,
	advert,
	scate1,
	scate2,
	hot,
	search,
	hotinc,
	shop,
	shops,
	myCart,
	myOrders,
	orderDetail,
	myUser,
	userUp,
	shopde,
	addCart,
	Cartdel,
	Cartalldel,
	check,
	orderset,
	shopList,
	Cartup,
	Cartplus,
	Cartreduce,
	code,
	jsauth,
	orderpay,
	orderdel,
	shopList1
} from './task/index.js'
export default {
	setweb,
	charts,
	advert,
	scate1,
	scate2,
	hot,
	search,
	hotinc,
	shop,
	shops,
	myCart,
	myOrders,
	orderDetail,
	myUser,
	userUp,
	shopde,
	addCart,
	Cartdel,
	Cartalldel,
	check,
	orderset,
	shopList,
	Cartup,
	Cartplus,
	Cartreduce,
	code,
	jsauth,
	orderpay,
	orderdel,
	shopList1
}
