<template>
	<router-view></router-view>
</template>
<!-- 'http://xejk.cs01.net/uploads/'+ -->
<script>
	export default {
		name: 'App'
	}
</script>

<style>
	/* #app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	} */
</style>
